import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Head from '~components/Head'
import Hero from '~components/Hero'
import Destinations from '~components/Destinations'
import { use as useSection } from '../components/sections'

export default class Homepage extends Component {
  render () {
    const { home, destinations, vaccinationRules } = this.props.data

    return (
      <>
        <Head
          title={home.title}
          description={home.metaDescription}
          image={home.featuredImage}
          location={this.props.location}
        />

        <Hero
          title={home.title}
          text={home.text}
          image={home.featuredImage}
          destinations={destinations.nodes}
        />

        <Destinations
          vaccinationRules={vaccinationRules}
          items={destinations.nodes}
        />

        {home.sections.map((section, index) => {
          const Component = useSection(section.__typename)
          return Component ? <Component key={index} data={section} position={index} /> : null
        })}
      </>
    )
  }
}

Homepage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export const query = graphql`
  query {
    vaccinationRules: contentfulVaccinationRules {
      outboundTestingTitle
      outboundTestingText
      outboundTestingLink
      outboundQuarantineTitle
      outboundQuarantineText
      outboundQuarantineLink
    }
    home: contentfulHomepage(slug: {regex: "/^(?!.*(sample))/"}) {
      title
      metaDescription
      featuredImage {
        id
        title
        desktop: fixed(width: 1600, height: 889, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        tablet: fixed(width: 1100, height: 611, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        thumb: fixed(width: 414, height: 736, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        openGraph: fixed(width: 1200, height: 627, quality: 80, resizingBehavior: CROP, cropFocus: CENTER) {
          src
        }
      }
      sections {
        ... on ContentfulSectionSignpost {
          ...Signpost
        }
        ... on ContentfulSectionText {
          ...Text
        }
      }
    }
    destinations: allContentfulDestination(filter: {slug: {regex: "/^(?!.*(sample))/"}}) {
      nodes {
        id
        title
        subtitle
        slug
        featuredImage {
          id
          title
          desktop: fixed(width: 1600, height: 889, quality: 80) {
            src
            srcSet
            srcSetWebp
          }
          tablet: fixed(width: 1100, height: 611, quality: 80) {
            src
            srcSet
            srcSetWebp
          }
          thumb: fixed(width: 414, height: 736, quality: 80) {
            src
            srcSet
            srcSetWebp
          }
          teaser: fixed(width: 398, height: 262, quality: 80) {
            srcSet
            srcSetWebp
            src
          }
        }
        location {
          lat
          lon
        }
        url
        featured
        alertLevel
        country {
          id
          name
          countryCode
          toggleVaccinationStatus
          outboundTestingTitle
          outboundTestingText
          outboundTestingLink
          outboundQuarantineTitle
          outboundQuarantineText
          outboundQuarantineLink
          inboundTestingTitle
          inboundTestingText
          inboundTestingLink
          inboundQuarantineTitle
          inboundQuarantineText
          inboundQuarantineLink
          vaccinatedTestingTitle
          vaccinatedTestingText
          vaccinatedTestingLink
          vaccinatedQuarantineTitle
          vaccinatedQuarantineText
          vaccinatedQuarantineLink
        }
        bookTestLink
        bookTestText
      }
    }
  }
`
