import React, { Component } from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import classNames from 'classnames'
import 'mapbox-gl/dist/mapbox-gl.css'
import styles from '~styles/components/map.module.scss'

class Map extends Component {
  constructor (props) {
    super(props)
    mapboxgl.accessToken = process.env.GATSBY_MAPBOXGL_API

    this.state = {
      lng: 15.1224,
      lat: 50.6098
    }
  }

  componentDidMount () {
    this.map = this.createMap()

    this.map.on('load', () => {
      this.addMapControls()
      this.addTwoTouchDragGesture()
      this.createMarkers()
      this.fitToMarkers()
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.markers !== this.props.markers) {
      this.setState({
        markers: this.props.markers
      })
    }
  }

  componentWillUnmount () {
    this.map.remove()
  }

  createMap () {
    const { lng, lat } = this.state

    return new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/birmingham-airport/ckqs8jecy50l619n2hll6vdbl',
      center: [lng, lat],
      scrollZoom: false
    })
  }

  createMarkers () {
    const markers = this.props.markers

    markers.forEach(({ title, slug, location, alertLevel }) => {
      new mapboxgl.Marker({
        element: this.createPin(alertLevel),
        scale: [28, 38],
        anchor: 'bottom'
      }).setLngLat([location.lon, location.lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 })
          .setHTML(`
            <span class="${styles.label}" aria-label="${title}">
              ${title}
            </span>
            <a href="/destinations/${slug}">View guide</a>
          `))
        .addTo(this.map)
    })
  }

  fitToMarkers () {
    const markers = this.props.markers
    const coordinates = markers.map(({ location }) => (
      [location.lon, location.lat]
    ))

    const bounds = coordinates.reduce((bounds, coord) => {
      return bounds.extend(coord)
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]))

    this.map.fitBounds(bounds, {
      maxZoom: 3,
      padding: { top: 75 }
    })
  }

  createPin (colour) {
    const el = document.createElement('div')
    el.classList.add(styles.marker)
    el.classList.add(styles[`marker${colour}`])
    return el
  }

  addMapControls () {
    this.map.addControl(new mapboxgl.NavigationControl())
  }

  // Two Touch Gesture Fix
  // https://github.com/mapbox/mapbox-gl-js/issues/2618#issuecomment-674570093
  addTwoTouchDragGesture () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      const map = this.map
      this.map.dragPan.disable()
      this.map.scrollZoom.disable()
      this.map.touchPitch.disable()

      this.map.on('touchstart', function (e) {
        var oe = e.originalEvent
        if (oe && 'touches' in oe) {
          if (oe.touches.length > 1) {
            oe.stopImmediatePropagation()
            map.dragPan.enable()
          } else {
            map.dragPan.disable()
          }
        }
      })
    }
  }

  render () {
    return (
      <div className={classNames(styles.wrapper, this.props.className)}>
        <div className={styles.map}>
          <div className={styles.inner} ref={(el) => { this.mapContainer = el }} />
        </div>
      </div>
    )
  }
}

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    location: PropTypes.object,
    alertLevel: PropTypes.string
  })),
  className: PropTypes.string
}

export default Map
