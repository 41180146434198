import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '~styles/components/bullet.module.scss'

const Bullet = (props) => {
  const status = props.status || 'Green'

  return (
    <span
      aria-label={`Alert level: ${status}`}
      className={classNames(
        styles.bullet,
        styles[status.toLowerCase()],
        props.className
      )}
    >
      {`Alert level: ${status}`}
    </span>
  )
}

Bullet.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string
}

export default Bullet
