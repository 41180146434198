import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Sprite from '~components/Sprite'
import classNames from 'classnames'
import Bullet from '~components/Bullet'
import { Link } from 'gatsby'
import styles from '~styles/components/destinationFilter.module.scss'

class DestinationFilter extends Component {
  constructor () {
    super()

    this.state = {
      value: ''
    }

    this.filterRef = React.createRef()
    this.inputRef = React.createRef()
    this.clickOutsideHandler = this.handleClickOutside.bind(this)
  }

  componentDidMount () {
    document.addEventListener('click', this.clickOutsideHandler)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.clickOutsideHandler)
  }

  handleToggleAutoComplete (toggle) {
    if (this.props.toggleAutoComplete) {
      this.props.toggleAutoComplete(toggle)
    }
  }

  handleClickOutside (e) {
    const filter = this.filterRef

    if (filter && !filter.current.contains(e.target)) {
      this.handleToggleAutoComplete()
    }
  }

  handleOnClear (e) {
    this.props.clearResults()

    this.setState({
      value: ''
    })
  }

  handleOnChange (value) {
    if (this.props.searchList) {
      this.props.searchList(value)
    }

    this.setState({
      value
    })
  }

  handleOnClick (value) {
    if (this.props.searchList) {
      this.props.searchList(value)
    }

    this.handleToggleAutoComplete(false)

    this.setState({
      value
    })
  }

  render () {
    const { list } = this.props

    return (
      <form
        ref={this.filterRef}
        className={classNames(styles.wrapper, this.props.className)}
        onSubmit={(e) => {
          this.handleOnClick(this.inputRef.current.value)
          e.preventDefault()
        }}
      >
        <label
          htmlFor="destination-filter"
          className="show-for-sr"
        >
          Search by country or destination
        </label>

        <div className={styles.filter}>
          <input
            className={styles.input}
            placeholder="Search by country or destination"
            id="destination-filter"
            onFocus={() => this.handleToggleAutoComplete(true)}
            onChange={(e) => this.handleOnChange(e.target.value)}
            value={this.state.value}
            autoComplete="off"
            type="text"
            ref={this.inputRef}
          />

          {!this.state.value && (
            <button
              type="button"
              aria-label="Toggle dropdown"
              onClick={(e) => this.inputRef.current.focus()}
              className={styles.button}
            >
              <Sprite
                name="chevron"
                fill={true}
                className={classNames(
                  styles.icon,
                  { [styles.iconDown]: !this.props.autocomplete },
                  { [styles.iconUp]: this.props.autocomplete }
                )}
              />
            </button>
          )}

          {this.state.value && (
            <button
              type="button"
              aria-label="Clear results"
              onClick={(e) => this.handleOnClear()}
              className={classNames(
                styles.button,
                styles.buttonClose
              )}
            >
              <Sprite
                name="cross"
                fill={true}
                className={classNames(
                  styles.icon
                )}
              />
            </button>
          )}

          <input
            type="submit"
            value="Search"
            className="hide"
          />

          {this.props.autocomplete && (
            <div className={styles.autocomplete}>
              {list && list.map(({ title, destinations }, index) => {
                return (
                  <div
                    className={styles.listGroup}
                    key={`${title}-${index}`}
                  >
                    <button
                      type="button"
                      className={classNames(styles.listItem, styles.listItemCountry)}
                      onClick={() => this.handleOnClick(title)}
                    >
                      {title}
                    </button>

                    {destinations && destinations.map(({ title, slug, alertLevel }, index) => (
                      <Link
                        to={`/destinations/${slug}`}
                        key={`${title}-${index}`}
                        className={styles.listItem}
                      >
                        <Bullet
                          status={alertLevel}
                          className={styles.bullet}
                        />

                        {title}
                      </Link>
                    ))}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </form>
    )
  }
}

DestinationFilter.propTypes = {
  value: PropTypes.string,
  list: PropTypes.array,
  autocomplete: PropTypes.bool,
  toggleAutoComplete: PropTypes.func,
  searchList: PropTypes.func,
  clearResults: PropTypes.func,
  className: PropTypes.string
}

export default DestinationFilter
