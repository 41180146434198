import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Map from '~components/Map'
import styles from '~styles/components/hero.module.scss'

class Hero extends Component {
  constructor () {
    super()

    this.state = {
      markers: []
    }
  }

  componentDidMount () {
    this.setState({
      markers: this.getMarkers()
    })
  }

  getMarkers () {
    const { destinations } = this.props
    let markers = []

    if (destinations.length) {
      markers = destinations.map(({ title, slug, location, alertLevel }) => (
        { title, slug, location, alertLevel }
      ))
    }

    return markers
  }

  render () {
    const { image, title } = this.props
    const { markers } = this.state

    return (
      <div className={styles.wrapper}>
        <div className={classNames('grid-container', styles.inner)}>
          <div className={classNames(styles.content)}>
            <span className={classNames(styles.icon)} aria-hidden="true">
              <Sprite name="logo" fill={true} />
            </span>

            <h1 className={styles.title}>
              {title}
            </h1>

            <div className={styles.steps}>
              <div className={styles.step}>
                <span
                  aria-hidden
                  className={styles.stepIcon}
                >
                  <Sprite name="marker" fill={true} />
                </span>

                <span className={styles.stepText}>
                  Select your
                  <br />
                  destination
                </span>
              </div>

              <div className={styles.step}>
                <span
                  aria-hidden
                  className={styles.stepIcon}
                >
                  <Sprite name="test" fill={true} />
                </span>

                <span className={styles.stepText}>
                  Get the latest
                  <br />
                  covid guidance
                </span>
              </div>

              <div className={styles.step}>
                <span
                  aria-hidden
                  className={styles.stepIcon}
                >
                  <Sprite name="calendar" fill={true} />
                </span>

                <span className={styles.stepText}>
                  Book and test
                  <br />
                  with confidence
                </span>
              </div>
            </div>
          </div>

          {(markers && markers) && (
            <Map markers={markers} className={styles.map} />
          )}
        </div>

        {image && (
          <div className={styles.media}>
            <picture>
              <source srcSet={image.desktop.srcSetWebp} type="image/webp" media="(min-width: 79.6875em)" />
              <source srcSet={image.desktop.srcSet} type="image/jpeg" media="(min-width: 79.6875em)" />
              <source srcSet={image.tablet.srcSetWebp} type="image/webp" media="(min-width: 43.125em)" />
              <source srcSet={image.tablet.srcSet} type="image/jpeg" media="(min-width: 43.125em)" />
              <img src={image.thumb.src} srcSet={image.thumb.srcSet} alt={image.title} className={styles.image} />
            </picture>
          </div>
        )}
      </div>
    )
  }
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
  image: PropTypes.object,
  destinations: PropTypes.array,
  className: PropTypes.string
}

export default Hero
