import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Destination from '~components/Destination'
import classNames from 'classnames'
import Listings from '~components/Listings'
import Filter from '~components/DestinationFilter'
import styles from '~styles/components/destinations.module.scss'
import { groupArrayObjectsByKey, sortArraybyKey, normaliseAccentedChars } from '~scripts/helpers'

class Destinations extends Component {
  constructor () {
    super()

    this.state = {
      featured: [],
      destinations: [],
      country: '',
      list: [],
      title: '',
      autocomplete: false
    }
  }

  componentDidMount () {
    this.setState({
      featured: this.getFeaturedDestinations(),
      list: this.getCountriesAndDestinations()
    })
  }

  getCountriesAndDestinations () {
    const { items } = this.props

    const list = items.map(({ title, slug, alertLevel, country }) => ({
      title: title,
      slug,
      country: country.name,
      alertLevel
    }))

    const groups = groupArrayObjectsByKey(list, 'country')
    return this.sortDestinationsIntoCountryGroups(groups)
  }

  sortDestinationsIntoCountryGroups (group) {
    const list = []

    Object.keys(group).forEach((key, value) => {
      const destination = sortArraybyKey(group[key], 'title')

      list.push({
        title: key,
        destinations: destination
      })
    })

    return sortArraybyKey(list, 'title')
  }

  getFeaturedDestinations () {
    const items = this.props.items

    return items.filter((item) => (
      item.featured === true
    ))
  }

  filterCountriesAndDestinations (value) {
    const { items } = this.props

    const results = items.filter((item) => {
      const destination = normaliseAccentedChars(item.title).toLowerCase()
      const country = normaliseAccentedChars(item.country.name).toLowerCase()
      return destination.startsWith(value) || country.startsWith(value)
    })

    const list = results.map(({ title, slug, alertLevel, country }) => ({
      title, slug, country: country.name, alertLevel
    }))

    const groups = groupArrayObjectsByKey(list, 'country')
    return this.sortDestinationsIntoCountryGroups(groups)
  }

  getDestinationListingsByValue (value) {
    if (value) {
      const items = this.props.items

      return items.filter((item) => {
        const country = normaliseAccentedChars(item.country.name)
        const title = normaliseAccentedChars(item.title)
        return value === country.toLowerCase() || value === title.toLowerCase()
      })
    }

    return []
  }

  onSearchList (value) {
    const keyword = normaliseAccentedChars(value).toLowerCase()
    const destinations = this.getDestinationListingsByValue(keyword)
    const count = destinations.length
    const featured = value.length > 0 ? [] : this.getFeaturedDestinations()
    const list = this.filterCountriesAndDestinations(keyword)

    this.setState({
      featured,
      destinations,
      list,
      title: this.formatTitle(count, value),
      autocomplete: list.length > 0
    })
  }

  clearResults () {
    this.setState({
      featured: this.getFeaturedDestinations(),
      destinations: [],
      list: this.getCountriesAndDestinations(),
      title: '',
      autocomplete: false
    })
  }

  formatTitle (count, value) {
    if (count === 1) {
      return `Showing ${count} result for "${value}"`
    }

    return `Showing ${count} results for "${value}"`
  }

  toggleAutoComplete (toggle) {
    this.setState({
      autocomplete: toggle
    })
  }

  render () {
    return (
      <>
        <section className={classNames('section section-destinations', styles.wrapper)}>
          <header className={styles.header}>
            <div className="grid-container">
              <div className="grid-x grid-margin-x grid-small-center">
                <div className={classNames('cell medium-8 large-5 xlarge-4')}>
                  <h2 className={styles.title}>
                    Discover our destinations
                  </h2>

                  <Filter
                    list={this.state.list}
                    autocomplete={this.state.autocomplete}
                    toggleAutoComplete={this.toggleAutoComplete.bind(this)}
                    searchList={this.onSearchList.bind(this)}
                    clearResults={() => this.clearResults()}
                    className={styles.filter}
                  />
                </div>
              </div>
            </div>
          </header>

          {this.state.featured && (
            <div className={styles.items}>
              {this.state.featured.map((item) => (
                <Destination
                  key={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  image={item.featuredImage}
                  url={item.url}
                  destination={item.slug}
                  country={item.country}
                  vaccinationRules={this.props.vaccinationRules}
                  alertLevel={item.alertLevel}
                  pricePrefix={item.pricePrefix}
                  price={item.price}
                  priceSuffix={item.priceSuffix}
                  className={styles.destination}
                />
              ))}
            </div>
          )}
        </section>

        {!this.state.featured.length && (
          <Listings
            title={this.state.title}
            items={this.state.destinations}
            vaccinationRules={this.props.vaccinationRules}
          />
        )}
      </>
    )
  }
}

Destinations.propTypes = {
  vaccinationRules: PropTypes.object,
  items: PropTypes.array
}

export default Destinations
