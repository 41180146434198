import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Teaser from '~components/Teaser'
import styles from '~styles/components/listings.module.scss'

export default class Listings extends Component {
  render () {
    const { items, vaccinationRules } = this.props

    return (
      <section className={classNames('section section-listings')}>
        <div className="grid-container">
          <header className={styles.header}>
            <h2 className={styles.heading}>
              {this.props.title}
            </h2>
          </header>

          <div className={classNames(styles.items, 'grid-x grid-margin-x grid-small-justify')}>
            {items && items.map((item) => (
              <div className={classNames(styles.item, 'cell medium-9 large-4')} key={item.id}>
                <Teaser
                  title={item.title}
                  country={item.country}
                  vaccinationRules={vaccinationRules}
                  alertLevel={item.alertLevel}
                  destination={item.slug}
                  pricePrefix={item.pricePrefix}
                  price={item.price}
                  priceSuffix={item.priceSuffix}
                  image={item.featuredImage.teaser}
                  url={item.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

Listings.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  vaccinationRules: PropTypes.object
}
