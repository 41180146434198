import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { summarise } from '~scripts/helpers'
import Sprite from '~components/Sprite'
import Link from '~components/Link'
import { connect } from 'react-redux'
import styles from '~styles/components/destination.module.scss'
import editorStyles from '~styles/components/editor.module.scss'
import buttonStyles from '~styles/components/button.module.scss'
import VaccineStatus from '~components/VaccineStatus'

class Destination extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  switchTab (index, e) {
    this.setState({
      activeTab: index
    })
  }

  isVaccinated () {
    const { vaccinated, country } = this.props
    const { toggleVaccinationStatus } = country
    return vaccinated && toggleVaccinationStatus
  }

  render () {
    const { vaccinationRules, country } = this.props
    const { name, toggleVaccinationStatus } = this.props.country

    return (
      <section className={classNames(styles.wrapper, this.props.className)}>
        <div className={classNames('grid-container', styles.inner)}>
          <div className={classNames(styles.content, 'grid-x')}>
            <div className={classNames('cell large-6')}>
              <span className={styles.pretitle}>
                Our destinations
              </span>

              {this.props.title && (
                <h2 className={classNames(styles.title)}>
                  {this.props.title}
                </h2>
              )}

              {this.props.subtitle && (
                <div className={classNames(editorStyles.editor, styles.text)}>
                  <p>
                    {summarise(this.props.subtitle, 120)}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.info}>
            <span className={styles.heading}>
              {name}
            </span>

            <div className={styles.container}>
              <div className={styles.items}>
                <div className={classNames(styles.item, styles.itemTabs)}>
                  <div className={styles.tabs}>
                    <button
                      type="button"
                      aria-label="Departing from BHX"
                      onClick={() => this.switchTab(0)}
                      disabled={this.state.activeTab === 0}
                      className={classNames(
                        styles.tab,
                        buttonStyles.button,
                        { [buttonStyles.grey]: this.state.activeTab !== 0 },
                        { [styles.tabActive]: this.state.activeTab === 0 }
                      )}
                    >
                      Departing from BHX
                    </button>

                    <button
                      type="button"
                      aria-label="Arriving back into BHX"
                      onClick={() => this.switchTab(1)}
                      disabled={this.state.activeTab === 1}
                      className={classNames(
                        styles.tab,
                        buttonStyles.button,
                        { [buttonStyles.grey]: this.state.activeTab !== 1 },
                        { [styles.tabActive]: this.state.activeTab === 1 }
                      )}
                    >
                      Arriving back into BHX
                    </button>
                  </div>

                  {toggleVaccinationStatus && (
                    <VaccineStatus className={styles.vaccinated} />
                  )}
                </div>

                {(this.state.activeTab === 0 && !this.isVaccinated()) && (
                  <>
                    <div className={classNames(styles.item, styles.itemRules)}>
                      {country.inboundTestingTitle && (
                        <span className={styles.infoTitle}>
                          {country.inboundTestingTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="test" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.inboundTestingText && (
                            <span className={styles.subtitle}>
                              {country.inboundTestingText}
                            </span>
                          )}

                          {country.inboundTestingLink && (
                            <Link href={country.inboundTestingLink} className={styles.link}>
                              Find out more

                              <span
                                className={classNames('icon', styles.externalIcon)}
                                aria-hidden="true"
                              >
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.item}>
                      {country.inboundQuarantineTitle && (
                        <span className={styles.infoTitle}>
                          {country.inboundQuarantineTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="quarantine" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.inboundQuarantineText && (
                            <span className={styles.subtitle}>
                              {country.inboundQuarantineText}
                            </span>
                          )}

                          {country.inboundQuarantineLink && (
                            <Link href={country.inboundQuarantineLink} className={styles.link}>
                              Find out more

                              <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(this.state.activeTab === 0 && this.isVaccinated()) && (
                  <>
                    <div className={classNames(styles.item, styles.itemRules)}>
                      {country.vaccinatedTestingTitle && (
                        <span className={styles.infoTitle}>
                          {country.vaccinatedTestingTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="test" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.vaccinatedTestingText && (
                            <span className={styles.subtitle}>
                              {country.vaccinatedTestingText}
                            </span>
                          )}

                          {country.vaccinatedTestingLink && (
                            <Link href={country.vaccinatedTestingLink} className={styles.link}>
                              Find out more

                              <span
                                className={classNames('icon', styles.externalIcon)}
                                aria-hidden="true"
                              >
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.item}>
                      {country.vaccinatedQuarantineTitle && (
                        <span className={styles.infoTitle}>
                          {country.vaccinatedQuarantineTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="quarantine" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.vaccinatedQuarantineText && (
                            <span className={styles.subtitle}>
                              {country.vaccinatedQuarantineText}
                            </span>
                          )}

                          {country.vaccinatedQuarantineLink && (
                            <Link href={country.vaccinatedQuarantineLink} className={styles.link}>
                              Find out more

                              <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(this.state.activeTab === 1 && !this.isVaccinated()) && (
                  <>
                    <div className={styles.item}>
                      {country.outboundTestingTitle && (
                        <span className={styles.infoTitle}>
                          {country.outboundTestingTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="test" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.outboundTestingText && (
                            <span className={styles.subtitle}>
                              {country.outboundTestingText}
                            </span>
                          )}

                          {country.outboundTestingLink && (
                            <Link href={country.outboundTestingLink} className={styles.link}>
                              Find out more

                              <span
                                className={classNames('icon', styles.externalIcon)}
                                aria-hidden="true"
                              >
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.item}>
                      {country.outboundQuarantineTitle && (
                        <span className={styles.infoTitle}>
                          {country.outboundQuarantineTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="quarantine" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {country.outboundQuarantineText && (
                            <span className={styles.subtitle}>
                              {country.outboundQuarantineText}
                            </span>
                          )}

                          {country.outboundQuarantineLink && (
                            <Link href={country.outboundQuarantineLink} className={styles.link}>
                              Find out more

                              <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(this.state.activeTab === 1 && this.isVaccinated()) && (
                  <>
                    <div className={styles.item}>
                      {vaccinationRules.outboundTestingTitle && (
                        <span className={styles.infoTitle}>
                          {vaccinationRules.outboundTestingTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="test" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {vaccinationRules.outboundTestingText && (
                            <span className={styles.subtitle}>
                              {vaccinationRules.outboundTestingText}
                            </span>
                          )}

                          {vaccinationRules.outboundTestingLink && (
                            <Link href={vaccinationRules.outboundTestingLink} className={styles.link}>
                              Find out more

                              <span
                                className={classNames('icon', styles.externalIcon)}
                                aria-hidden="true"
                              >
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.item}>
                      {vaccinationRules.outboundQuarantineTitle && (
                        <span className={styles.infoTitle}>
                          {vaccinationRules.outboundQuarantineTitle}
                        </span>
                      )}

                      <div className={styles.row}>
                        <span aria-hidden="true" className={styles.icon}>
                          <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                            <Sprite name="quarantine" />
                          </span>
                        </span>

                        <div className={styles.content}>
                          {vaccinationRules.outboundQuarantineText && (
                            <span className={styles.subtitle}>
                              {vaccinationRules.outboundQuarantineText}
                            </span>
                          )}

                          {vaccinationRules.outboundQuarantineLink && (
                            <Link href={vaccinationRules.outboundQuarantineLink} className={styles.link}>
                              Find out more

                              <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                                <Sprite name="external" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={classNames(styles.price)}>
                <span className={styles.infoTitle}>
                  Covid guide
                </span>

                <span className={styles.label}>
                  Keep in the know with the latest covid news
                </span>

                <Link
                  href={`/destinations/${this.props.destination}`}
                  className={classNames(
                    styles.button,
                    buttonStyles.button
                  )}
                >
                  Read the covid guide
                </Link>
              </div>
            </div>
          </div>
        </div>

        {this.props.image && (
          <div className={styles.media}>
            <picture>
              <source srcSet={this.props.image.desktop.srcSetWebp} type="image/webp" media="(min-width: 79.6875em)" />
              <source srcSet={this.props.image.desktop.srcSet} type="image/jpeg" media="(min-width: 79.6875em)" />
              <source srcSet={this.props.image.tablet.srcSetWebp} type="image/webp" media="(min-width: 43.125em)" />
              <source srcSet={this.props.image.tablet.srcSet} type="image/jpeg" media="(min-width: 43.125em)" />
              <img src={this.props.image.thumb.src} srcSet={this.props.image.thumb.srcSet} alt={this.props.image.title} className={styles.image} />
            </picture>
          </div>
        )}
      </section>
    )
  }
}

Destination.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alertLevel: PropTypes.string,
  country: PropTypes.object,
  destination: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.object,
  className: PropTypes.string,
  vaccinated: PropTypes.bool,
  vaccinationRules: PropTypes.object
}

const mapStateToProps = (state) => ({
  vaccinated: state.global.vaccinated
})

export default connect(mapStateToProps)(Destination)
